// uuid returns an RFC 4122 compliant universally unique
// identifier using the crypto API
export function uuidv4(): string {
    // get sixteen unsigned 8 bit random values
    const u = window.crypto.getRandomValues(new Uint8Array(16));

    // set the version bit to v4
    u[6] = (u[6] & 0x0f) | 0x40;

    // set the variant bit to "don't care" (yes, the RFC
    // calls it that)
    u[8] = (u[8] & 0xbf) | 0x80;

    // hex encode them and add the dashes
    let uid = '';
    uid += u[0].toPaddedHex();
    uid += u[1].toPaddedHex();
    uid += u[2].toPaddedHex();
    uid += u[3].toPaddedHex();
    uid += '-';

    uid += u[4].toPaddedHex();
    uid += u[5].toPaddedHex();
    uid += '-';

    uid += u[6].toPaddedHex();
    uid += u[7].toPaddedHex();
    uid += '-';

    uid += u[8].toPaddedHex();
    uid += u[9].toPaddedHex();
    uid += '-';

    uid += u[10].toPaddedHex();
    uid += u[11].toPaddedHex();
    uid += u[12].toPaddedHex();
    uid += u[13].toPaddedHex();
    uid += u[14].toPaddedHex();
    uid += u[15].toPaddedHex();

    return uid;
}

declare global {
    interface Number {
        toPaddedHex: () => string;
    }
}

Number.prototype.toPaddedHex = function (): string {
    return String('0' + this.toString(16)).slice(-2);
};
